import React, { useState, useEffect } from "react";
import ReactFlagsSelect from "react-flags-select";

export default function BusinessType({ user, onFormChange, handleSubTab }) {
  const [selected, setSelected] = useState(
    user?.businessCountry ? user?.businessCountry : ""
  );
  const [businessType, setBusinessType] = useState(
    user?.businessType ? user?.businessType : ""
  );

  const options = [
    { value: "SP", label: "Sole Proprietorship" },
    { value: "NGO", label: "Non Governmental Organization" },
    { value: "LLC", label: "Limited Liability Company" },
  ];

  function handleSelect(event) {
    setBusinessType(event.target.value);
  }

  function handleFormChange() {
    onFormChange(selected, businessType);
    handleSubTab(1, 1); //second sub tab, pass the active tab index and active sub tab index, it checks and move to the next tab
  }

  useEffect(() => {
    if (user?.account) {
      handleSubTab(1, 1); //second sub tab, pass the active tab index and active sub tab index, it checks and move to the next tab
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div>
      <div className="onboarding-card-form-details">
        <div className="business-form-fields">
          <h1>Business information</h1>
          <p>Provide more information about your business.</p>
          <div className="form-group">
            <label className="label-form">Business country</label>
            <ReactFlagsSelect
              className="form__select_flag"
              placeholder="Select your business country"
              selected={selected}
              disabled
              onSelect={(code) => setSelected(code)}
              searchable="true"
              // countries={["NG", "KE", "UG", "TZ", "RW", "GH", "CI", "CM"]}
            />
          </div>
          <div className="form-group">
            <label className="label-form">Business type</label>
            <select
              value={businessType}
              onChange={handleSelect}
              className="reg-select"
            >
              <option value="">Select an option</option>
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="onboarding-card-form-footer">
        <button
          type="submit"
          disabled={!(selected && businessType)}
          className="custom-modal__close-button"
          onClick={handleFormChange}
        >
          Continue
        </button>
      </div>
    </div>
  );
}
