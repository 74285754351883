import React, { useState } from "react";
import { Tabs, Button, Avatar, Spin } from "antd";
import { RightCircleOutlined, EditOutlined } from "@ant-design/icons";
import randomColor from "randomcolor";
import moment from "moment";
import ReactCountryFlag from "react-country-flag";
import * as ctzc from "country-tz-currency";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "../../utilities/hooks";
import { fetchBeneficiariesAction } from "../../services/actions";
import EditBeneficiariesModal from "../../components/modals/beneficiaries/EditBeneficiaries";
import TagManager from "react-gtm-module";
import { AccessControl } from "accesscontrol";
import GeneralTable from "../../components/GeneralTable";

const tagManagerArgs = {
  dataLayer: {
    event: "b2b_edit_beneficiary_btn",
  },
};

export default function BeneficiariesList({ beneficiaries, loading, user }) {
  const { roles } = useSelector((state) => state.RoleReducer);
  const navigate = useNavigate();
  const [editBeneficiaryModal, setEditBeneficiaryModal] = useState(false);
  const [itemDetails, setItemDetails] = useState({});

  const ac = new AccessControl(roles?.permissions);

  function checkAccess(value) {
    const permission = ac.can(user?.role).createAny(value);
    return permission;
  }

  const benny = beneficiaries?.beneficiaries;
  let bene;
  if (benny) {
    bene = [...benny];
  }

  const now = moment().format("YYYY-MM-DD");
  const dateLimits = {
    today: { from: moment().startOf("day").format("YYYY-MM-DD"), to: now },
    week: {
      from: moment().subtract(1, "week").startOf("day").format("YYYY-MM-DD"),
      to: now,
    },
    month: {
      from: moment().subtract(1, "month").startOf("day").format("YYYY-MM-DD"),
      to: now,
    },
    year: {
      from: moment().subtract(1, "year").startOf("day").format("YYYY-MM-DD"),
      to: now,
    },
  };

  const filterOptions = [{ label: "Date", clickable: true }];
  // const filterOptions = [];
  const filterSelectOptions = {
    Date: [
      "Select date",
      "Today",
      "This week",
      "This month",
      "Past year",
      "Custom",
    ],
  };

  const BeneficiaryAvatar = (beneficiary) => {
    const bgColor = randomColor({
      luminosity: "dark",
    });
    return (
      <Avatar style={{ marginRight: 10, backgroundColor: bgColor }}>
        {`${beneficiary.firstName.charAt(0)}${beneficiary.lastName.charAt(
          0
        )}`.toLocaleUpperCase()}
      </Avatar>
    );
  };

  const columnsAll = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      width: 220,
      render: (firstName, row) => {
        if (firstName) {
          return (
            <div className="d-flex">
              {BeneficiaryAvatar(row)}
              {`${firstName} ${row.lastName}`.toLocaleUpperCase()}
            </div>
          );
        }
      },
    },
    {
      title: "Phone number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 180,
      render: (phoneNumber) => {
        if (phoneNumber) {
          return <div>{phoneNumber}</div>;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Bank name",
      dataIndex: "bankName",
      key: "bankName",
      width: 200,
      render: (bankName) => {
        if (bankName) {
          return <div>{bankName.substring(0, 11)}...</div>;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Account name",
      dataIndex: "bankAccountName",
      key: "bankAccountName",
      width: 220,
      render: (bankAccountName) => {
        if (bankAccountName) {
          return <div>{bankAccountName.substring(0, 15)}...</div>;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Account number",
      dataIndex: "bankAccountNumber",
      key: "bankAccountNumber",
      width: 150,
      render: (bankAccountNumber) => {
        if (bankAccountNumber) {
          return <div>{bankAccountNumber}</div>;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: 120,
      render: (country) => {
        if (country) {
          return (
            <div>
              <ReactCountryFlag
                countryCode={country}
                svg
                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                cdnSuffix="svg"
                title="US"
                style={{
                  fontSize: "1.5em",
                  lineHeight: "2em",
                  borderRadius: "50px",
                }}
              />
              &nbsp; &nbsp;
              {ctzc.getCountryByCode(country)?.countryName}
            </div>
          );
        } else {
          return "-";
        }
      },
    },

    {
      title: "",
      key: "id",
      dataIndex: "id",
      width: 120,
      render: (id, row) => {
        return (
          <div>
            {checkAccess("payouts").granted ? (
              <Button
                type="link"
                style={{
                  color: "#48484D",
                  display: "flex",
                  alignItems: "baseline",
                }}
                icon={<RightCircleOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  goToPay(row);
                }}
              >
                Send Money
              </Button>
            ) : null}
          </div>
        );
      },
    },
    {
      title: "",
      key: "id",
      dataIndex: "id",
      width: 100,
      render: (id, row) => {
        return (
          <div>
            {checkAccess("payouts").granted ? (
              <Button
                type="link"
                style={{ color: "#48484D" }}
                icon={<EditOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  editBeneficiary(row);
                }}
              >
                Edit
              </Button>
            ) : null}
          </div>
        );
      },
    },
  ];

  let editBeneficiary = (user) => {
    TagManager.dataLayer(tagManagerArgs);
    setItemDetails(user);
    setEditBeneficiaryModal(true);
  };

  const columnsMomo = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      width: 220,
      render: (firstName, row) => {
        if (firstName) {
          return (
            <div onClick={() => goToBeneficiary(row)} className="d-flex">
              {BeneficiaryAvatar(row)}
              {`${firstName} ${row.lastName}`.toLocaleUpperCase()}
            </div>
          );
        }
      },
    },
    {
      title: "Phone number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 180,
      render: (phoneNumber, row) => {
        if (phoneNumber) {
          return <div onClick={() => goToBeneficiary(row)}>{phoneNumber}</div>;
        } else {
          return "-";
        }
      },
    },

    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: 150,
      render: (country, row) => {
        if (country) {
          return (
            <div onClick={() => goToBeneficiary(row)}>
              <ReactCountryFlag
                countryCode={country}
                svg
                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                cdnSuffix="svg"
                title="US"
                style={{
                  fontSize: "1.5em",
                  lineHeight: "2em",
                  borderRadius: "50px",
                }}
              />
              &nbsp; &nbsp;
              {ctzc.getCountryByCode(country)?.countryName}
            </div>
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: "",
      key: "id",
      dataIndex: "id",
      width: 120,
      render: (id, row) => {
        return (
          <div>
            {checkAccess("payouts").granted ? (
              <Button
                type="link"
                style={{ color: "#48484D" }}
                icon={<RightCircleOutlined />}
                onClick={() => goToPay(row)}
              >
                Send Money
              </Button>
            ) : null}
          </div>
        );
      },
    },
    {
      title: "",
      key: "id",
      dataIndex: "id",
      width: 100,
      render: (id, row) => {
        return (
          <div>
            {checkAccess("payouts").granted ? (
              <Button
                type="link"
                style={{ color: "#48484D" }}
                icon={<EditOutlined />}
                onClick={() => editBeneficiary(row)}
              >
                Edit
              </Button>
            ) : null}
          </div>
        );
      },
    },
  ];

  const dispatch = useDispatch();

  function handleTabClick(keys, event) {
    let params;
    switch (keys) {
      case "1":
        params = {
          limit: 10,
          page: 1,
        };
        break;
      case "2":
        params = {
          type: "bank",
          limit: 10,
          page: 1,
        };
        break;
      case "3":
        params = {
          type: "momo",
          limit: 10,
          page: 1,
        };
        break;
      case "4":
        params = {
          type: "eversend",
          limit: 10,
          page: 1,
        };
        break;
      default:
        params = {
          limit: 10,
          page: 1,
        };
        break;
    }
    dispatch(fetchBeneficiariesAction({ params }, false));
  }

  let goToPay = (row) => {
    navigate("/payouts", { state: row });
  };

  const goToBeneficiary = (row) => {
    navigate(`/beneficiaries/details/${row.id}`);
  };

  const { TabPane } = Tabs;

  const paginateAll = (page, pageSize) => {
    const params = {
      limit: pageSize,
      page: page,
    };
    dispatch(fetchBeneficiariesAction({ params }, false));
  };

  const handleInputChange = (value) => {
    const params = {
      search: value,
    };
    // console.log(params);
    dispatch(
      fetchBeneficiariesAction({
        params,
      })
    );
  };

  const filterTransactions = (value, custom) => {
    if (
      (value === "Today" ||
        value === "This week" ||
        value === "This month" ||
        value === "Past year") &&
      !custom
    ) {
      const params = {
        from: dateLimits[
          value === "Today"
            ? "today"
            : value === "This week"
            ? "week"
            : value === "This month"
            ? "month"
            : value === "Past year"
            ? "year"
            : "today"
        ].from,
        to: dateLimits[
          value === "Today"
            ? "today"
            : value === "This week"
            ? "week"
            : value === "This month"
            ? "month"
            : value === "Past year"
            ? "year"
            : "today"
        ].to,
      };
      dispatch(
        fetchBeneficiariesAction({
          params,
        })
      );
    } else if (value === "Custom") {
      const params = {
        from: custom.from,
        to: custom.to,
      };
      dispatch(
        fetchBeneficiariesAction({
          params,
        })
      );
    } else {
      dispatch(fetchBeneficiariesAction({}));
    }
  };

  return (
    <div className="bene-tabs">
      <Tabs defaultActiveKey={1} onTabClick={handleTabClick}>
        <TabPane tab="All" key="1">
          <div className="card-table-h1">
            <Spin spinning={loading}>
              <GeneralTable
                columns={columnsAll}
                placeHolder="Search beneficiary name, phone number or email"
                filter={filterOptions}
                filterSelect={filterSelectOptions}
                download={false}
                filterTransactions={filterTransactions}
                handleInputChange={handleInputChange}
                pagination={beneficiaries}
                paginateAll={paginateAll}
                data={bene}
                onRowClick={(record) => goToBeneficiary(record)}
              />
            </Spin>
          </div>
        </TabPane>
        <TabPane tab="Bank" key="2">
          <div className="card-table-h1">
            <Spin spinning={loading}>
              <GeneralTable
                columns={columnsAll}
                placeHolder="Search beneficiary name, phone number or email"
                filter={filterOptions}
                filterSelect={filterSelectOptions}
                download={false}
                filterTransactions={filterTransactions}
                handleInputChange={handleInputChange}
                pagination={beneficiaries}
                paginateAll={paginateAll}
                data={bene}
              />
            </Spin>
          </div>
        </TabPane>
        <TabPane tab="MOMO" key="3">
          <div className="card-table-h1">
            <Spin spinning={loading}>
              <GeneralTable
                columns={columnsMomo}
                placeHolder="Search beneficiary name, phone number or email"
                filter={filterOptions}
                filterSelect={filterSelectOptions}
                download={false}
                filterTransactions={filterTransactions}
                handleInputChange={handleInputChange}
                pagination={beneficiaries}
                paginateAll={paginateAll}
                data={bene}
              />
            </Spin>
          </div>
        </TabPane>
        <TabPane tab="Eversend" key="4">
          <div className="card-table-h1">
            <Spin spinning={loading}>
              <GeneralTable
                columns={columnsMomo}
                placeHolder="Search beneficiary name, phone number or email"
                filter={filterOptions}
                filterSelect={filterSelectOptions}
                download={false}
                filterTransactions={filterTransactions}
                handleInputChange={handleInputChange}
                pagination={beneficiaries}
                paginateAll={paginateAll}
                data={bene}
              />
              &nbsp;
            </Spin>
          </div>
        </TabPane>
      </Tabs>

      {editBeneficiaryModal ? (
        <EditBeneficiariesModal
          show={editBeneficiaryModal}
          onClose={() => {
            setEditBeneficiaryModal(false);
            dispatch(fetchBeneficiariesAction({}));
          }}
          item={itemDetails}
          beneficiaries={beneficiaries}
          // itemPosition={itemPosition}
        />
      ) : null}
    </div>
  );
}
