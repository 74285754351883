import React, { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useSelector } from "../../../utilities/hooks";

export default function ThirdForm({ goToNextStep, title, inputDetails }) {
  const { sourceCountries } = useSelector((state) => state.CountryReducer);
  const allowedCountriesArray = sourceCountries.map((item) => item.code);
  const [selected, setSelected] = useState(
    inputDetails?.country ? inputDetails?.country : ""
  );
  const [selectedOptionReg, setSelectedOptionReg] = useState(
    inputDetails?.businessRegistered ? inputDetails?.businessRegistered : ""
  );

  const handleChangeReg = (e) => {
    setSelectedOptionReg(e.target.value);
  };

  return (
    <div>
      <h2>{title}</h2>

      <div className="form-group">
        <label className="label-form">What country is your business in?</label>
        <ReactFlagsSelect
          className="form__select_flag"
          placeholder="Select your business country"
          selected={selected}
          onSelect={(code) => setSelected(code)}
          searchable="true"
          countries={allowedCountriesArray}
        />
      </div>

      <div className="form-group">
        <label className="label-form">
          Is your business government registered?
        </label>
        <div className="custom-radio-group">
          <label className="custom-radio">
            <input
              type="radio"
              id="radio-input-1"
              name="radio-group"
              value="yes"
              onChange={handleChangeReg}
              checked={selectedOptionReg === "yes"}
            />
            <span className="checkmark"></span>
            Yes, my business is registered.
          </label>
          <label className="custom-radio">
            <input
              type="radio"
              id="radio-input-2"
              name="radio-group"
              value="no"
              onChange={handleChangeReg}
              checked={selectedOptionReg === "no"}
            />
            <span className="checkmark"></span>
            No, my business is not registered.
          </label>
        </div>
      </div>

      <button
        type="submit"
        disabled={!(selected && selectedOptionReg)}
        onClick={() => goToNextStep(selected, selectedOptionReg)}
        className="submit-reg"
      >
        Continue
      </button>
    </div>
  );
}
