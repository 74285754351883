import React, { useState, useEffect } from "react";
import PinInput from "react-pin-input";
import alert from "../../../assets/images/alert.svg";
import { message } from "antd";
import { useSelector, useDispatch } from "../../../utilities/hooks";
import {
  businessInfoAction,
  businessInfoActionReset,
} from "../../../services/actions";
import { fetchProfileAction } from "../../../services/reducers/authReducers/profileReducer";

import Loader from "../../../components/Loader";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    event: "business_account _form_submission",
  },
};

export default function TransactionPin({ user, onFormChange }) {
  const [otpInput, setOtpInput] = useState();
  const [otpInputTwo, setOtpInputTwo] = useState();
  const dispatch = useDispatch();
  const { loading, account } = useSelector((state) => state.businessInfo);

  function handleInputPinChange(otpInput) {
    setOtpInput(otpInput);
  }

  function handleInputPinChangeTwo(otpInput) {
    setOtpInputTwo(otpInput);
  }

  function handleFormChange() {
    if (otpInput === otpInputTwo) {
      const formFields = JSON.parse(localStorage.getItem("formFieldsStorage"));
      const payload = {
        name: formFields.name,
        address: formFields.address,
        town: formFields.town,
        type: formFields.businessType,
        website: formFields.website,
        pin: otpInput,
        country: formFields.businessCountry,
      };

      if (formFields.businessCountry === "NG") {
        payload.bvn = formFields.bvn;
      }
      dispatch(
        businessInfoAction({
          ...payload,
        })
      );

      TagManager.dataLayer(tagManagerArgs);
      // console.log(formFields, "all form fields");
    } else {
      message.error("Transaction pin does not match", 2);
    }
  }

  useEffect(() => {
    if (account) {
      message.success("Your account has been created successfully");
      dispatch(fetchProfileAction());
      dispatch(businessInfoActionReset());
      window.localStorage.removeItem("formFieldsStorage");
      onFormChange(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, dispatch]);

  useEffect(() => {
    if (user?.account) {
      onFormChange(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div>
      <div className="onboarding-card-form-details">
        <div className="business-form-fields">
          <h1>Create transaction pin</h1>
          <p>Secure your transactions. </p>
          <div className="d-flex align-items-start justify-content-between note-business">
            <img src={alert} alt="alert" style={{ marginRight: "10px" }} />
            <h2>
              Use a pin you can remember, do not share this pin with anyone.
            </h2>
          </div>
          <div className="pin-code-div">
            <div className="form-group">
              <label className="label-form">Create transaction pin</label>
              <PinInput
                focus
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                inputStyle={{
                  border: "1px solid #CBCAE5",
                  borderRadius: "8px",
                  color: "#949494",
                }}
                length={4}
                type="numeric"
                onComplete={handleInputPinChange}
              />
            </div>
            <br />
            <div className="form-group">
              <label className="label-form">Re-enter transaction pin</label>
              <PinInput
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                inputStyle={{
                  border: "1px solid #CBCAE5",
                  borderRadius: "8px",
                  color: "#949494",
                }}
                length={4}
                type="numeric"
                onComplete={handleInputPinChangeTwo}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="onboarding-card-form-footer">
        <button
          type="submit"
          disabled={!(otpInput && otpInputTwo)}
          className="custom-modal__close-button"
          onClick={handleFormChange}
        >
          {loading ? (
            <span className="d-flex align-items-center justify-content-between">
              <Loader dark={false} /> Submit
            </span>
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </div>
  );
}
