import React, { useState, useEffect } from "react";

export default function BusinessAddress({
  user,
  onFormChange,
  handleNextTab,
  uploaded,
  totalUpload,
}) {
  const [inputValues, setInputValues] = useState({
    town: "",
    address: "",
    website: "",
  });

  function handleChangeInput(event) {
    const inputValue = event.target.value.replace(/[^a-zA-Z0-9 ]/g, "");

    setInputValues({
      ...inputValues,
      [event.target.name]:
        event.target.name === "address" ? inputValue : event.target.value,
    });
  }

  function handleSubmit() {
    onFormChange(inputValues);
    handleNextTab(1); //last sub tab so pass the current tab index so it marks as complete and take to a new tab
  }

  useEffect(() => {
    if (user?.account) {
      handleNextTab(1); //last sub tab so pass the current tab index so it marks as complete and take to a new tab
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div>
      <div className="onboarding-card-form-details">
        <div className="business-form-fields">
          <h1>Business information</h1>
          <p>Provide more information about your business.</p>
          <div className="form-group">
            <label className="label-form">Business town</label>
            <input
              type="text"
              name="town"
              value={inputValues.town}
              onChange={handleChangeInput}
              placeholder="Enter town name"
              className="reg-input"
            />
          </div>
          <div className="form-group">
            <label className="label-form">Business address</label>
            <textarea
              type="text"
              name="address"
              value={inputValues.address}
              onChange={handleChangeInput}
              onPaste={(e) => {
                e.preventDefault(); // Prevent the default pasting behavior

                // Get the pasted text and remove newline characters
                const pastedText = e.clipboardData
                  .getData("text/plain")
                  .replace(/[\r\n]+/g, " ");

                // Apply the regex to filter out non-alphanumeric characters and spaces
                const filteredText = pastedText.replace(/[^a-zA-Z0-9 ]/g, "");

                // Insert the modified text into the textarea
                document.execCommand("insertText", false, filteredText);
              }}
              placeholder="Text…"
              className="reg-input-area"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent the default Enter behavior
                }
              }}
            />
          </div>
          <div className="form-group">
            <label className="label-form">Business website </label>
            <input
              type="text"
              name="website"
              value={inputValues.website}
              onChange={handleChangeInput}
              placeholder="https:// Text... (facebook page can do)"
              className="reg-input"
            />
          </div>
        </div>
      </div>
      <div className="onboarding-card-form-footer">
        <button
          type="submit"
          disabled={!(inputValues.town.trim() && inputValues.address.trim())}
          className="custom-modal__close-button"
          onClick={handleSubmit}
        >
          Continue
        </button>
      </div>
    </div>
  );
}
