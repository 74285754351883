import React, { useState, useEffect, useCallback } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { IdleTimerProvider, useIdleTimer } from "react-idle-timer";
import { Modal, message, Button } from "antd";
import { SESSION_HASH } from "../../utilities/constants";
import makeAPICall from "../../utilities/apiUtils";
import "../../assets/css/style.css";
import PublicRouteCheck from "../../routes/Public.routes";
import PrivateRouteCheck from "../../routes/Private.routes";
import LoginContainer from "../../pages/auth/login/Login.container";
import TwoFactorEmail from "../../pages/auth/login/TwoFactorEmail";
import ResetContainer from "../../pages/auth/reset/ResetContainer";
import SignupContainer from "../../pages/auth/signup/SignupContainer";
import VerificationEmail from "../../pages/auth/signup/VerificationEmail";
import VerificationSuccess from "../../pages/auth/signup/VerificationSuccess";
import MainRoute from "../../routes/Dashboard.routes";
import FinishBeneficiaryBulkUpload from "../modals/beneficiaries/FinishBeneficiaryBulkUpload";
import ExchangeModal from "../modals/exchange";
import AddMoneyModal from "../modals/addMoneyWallet";
import PayoutModal from "../modals/payout";
import CreateCardModal from "../modals/createCard";
import BeneficiaryModal from "../modals/payout/beneficiary";
import { useSelector, useDispatch } from "../../utilities/hooks";
import {
  toggleExchangeModalAction,
  toggleAddMoneyModalAction,
  toggleSendMoneyModalAction,
  toggleSendMoneyBeneficiaryModalAction,
  toggleCreateCardModalAction,
} from "../../services/actions";
import { isLogin } from "../../utilities/authUtils";

import "bootstrap/dist/js/bootstrap.bundle.min.js";
import RequestTagModal from "../RequestTagModal";
import { selectIsModalOpen, closeModal } from "../../services/reducers/modal";

// import { key } from "../../utilities/helperFunctions";
// import displayErrorMessage from "../notifyBox";

function App(props) {
  message.config({
    duration: 5,
    maxCount: 1,
    rtl: false,
  });

  const { showModal } = useSelector(
    (state) => state.beneficiariesReducer.bulkUpload
  );
  const data = window.localStorage.getItem(SESSION_HASH);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reset } = useIdleTimer();

  const {
    isExchange,
    isExchangeValue,
    isAddMoney,
    isAddMoneyValue,
    isSendMoney,
    isCreateCard,
    isSendMoneyBeneficiary,
    isSendMoneyBeneficiaryValue,
    isSendMoneyState,
    isType,
  } = useSelector((state) => state.walletsReducer);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [seconds, setSeconds] = useState();
  const isTagModalOpen = useSelector(selectIsModalOpen("tagModal"));
  const handleCloseTagModal = () => dispatch(closeModal("tagModal"));

  const logOut = useCallback(async () => {
    navigate("/login");
    return makeAPICall({
      path: `auth/logout?session=${data}`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          window.localStorage.clear();
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
        window.localStorage.clear();
        window.location.reload();
      });
  }, [data, navigate]);

  const handleOnIdle = () => {
    setIsModalOpen(false);
    logOut();
  };

  const onClose = () => {
    dispatch(toggleExchangeModalAction());
  };

  const handleCloseModal = () => {
    dispatch(toggleAddMoneyModalAction());
  };

  const handleCloseCreateCardModal = () => {
    dispatch(toggleCreateCardModalAction());
  };

  const handleClosePayoutModal = () => {
    dispatch(toggleSendMoneyModalAction());
  };

  const handleCloseBeneficiaryPayoutModal = () => {
    dispatch(toggleSendMoneyBeneficiaryModalAction());
  };

  const onPrompt = () => {
    setIsModalOpen(true);
    setSeconds(60);
  };

  const handleOk = () => {
    message.success("You will be logged out due to inactivity");
    setIsModalOpen(false);
    logOut();
  };

  const onActive = (event) => {
    handleCancel();
  };

  function handleCancel() {
    reset();
    setSeconds();
    setIsModalOpen(false);
  }

  useEffect(() => {
    if (!seconds) return;

    const intervalId = setInterval(() => {
      setSeconds(seconds - 1);
    }, 1000);

    if (seconds <= 0) {
      logOut();
    }

    return () => clearInterval(intervalId);
  }, [seconds, logOut]);

  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRouteCheck component={LoginContainer} title="Login" />
          }
        />
        <Route
          path="/auth"
          element={
            <PublicRouteCheck component={TwoFactorEmail} title="2FA Auth" />
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRouteCheck
              component={ResetContainer}
              title="Forgot Password"
            />
          }
        />
        <Route
          path="/signup"
          element={
            <PublicRouteCheck component={SignupContainer} title="Register" />
          }
        />
        <Route
          path="/verify"
          element={
            <PublicRouteCheck
              component={VerificationEmail}
              title="Verify Email"
            />
          }
        />
        <Route
          path="/success"
          element={
            <PublicRouteCheck component={VerificationSuccess} title="Success" />
          }
        />
        <Route
          path="/*"
          element={
            <IdleTimerProvider
              timeout={1000 * 60 * 15} // 15 minutes
              promptTimeout={1000 * 60 * 14} // 14 minutes
              onPrompt={onPrompt}
              onActive={onActive}
              onIdle={handleOnIdle}
            >
              <PrivateRouteCheck component={MainRoute} />
            </IdleTimerProvider>
          }
        />
      </Routes>

      {isLogin() && (
        <>
          <div className="auth-modal-auto">
            {isModalOpen ? (
              <Modal
                title="You Have Been Idle!"
                visible={isModalOpen}
                centered
                closable={false}
                footer={[
                  <Button
                    type="primary"
                    onClick={handleOk}
                    style={{
                      background: "#F0F0F0",
                      borderRadius: "4px",
                      border: "none",
                      color: "#5A50FE",
                      width: "30%",
                    }}
                  >
                    Logout
                  </Button>,
                  <Button
                    type="primary"
                    onClick={handleCancel}
                    style={{
                      background: "#5A50FE",
                      borderRadius: "4px",
                      width: "30%",
                    }}
                  >
                    Stay
                  </Button>,
                ]}
              >
                <p>You will be signed out in {seconds}. Do you want to stay?</p>
              </Modal>
            ) : null}
          </div>

          {showModal ? <FinishBeneficiaryBulkUpload show={showModal} /> : null}
          {isExchange ? (
            <ExchangeModal
              exchangeCurrency={isExchangeValue}
              show={isExchange}
              onClose={onClose}
            />
          ) : null}
          {isAddMoney ? (
            <AddMoneyModal
              show={isAddMoney}
              currentWalletDefault={isAddMoneyValue}
              onClose={handleCloseModal}
              type={isType}
            />
          ) : null}
          {isSendMoney ? (
            <PayoutModal onClose={handleClosePayoutModal} show={isSendMoney} />
          ) : null}
          {isCreateCard ? (
            <CreateCardModal
              onClose={handleCloseCreateCardModal}
              show={isCreateCard}
            />
          ) : null}
          {isSendMoneyBeneficiary ? (
            <BeneficiaryModal
              onClose={handleCloseBeneficiaryPayoutModal}
              show={isSendMoneyBeneficiary}
              beneficiary={isSendMoneyBeneficiaryValue}
              isFirstTime={isSendMoneyState}
            />
          ) : null}
          <RequestTagModal
            isOpen={isTagModalOpen}
            onClose={handleCloseTagModal}
          />
        </>
      )}

      <React.Fragment>
        {onClose} {handleCloseModal} {handleClosePayoutModal}
        {handleCloseCreateCardModal}
        {handleCloseBeneficiaryPayoutModal}
      </React.Fragment>
    </React.Fragment>
  );
}

export default App;
