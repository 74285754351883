import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import ReactCountryFlag from "react-country-flag";
import * as ctzc from "country-tz-currency";
import moment from "moment";
import { Button, Col, Row } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
  DownCircleOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import trans from "../../assets/images/transaction.png";
import { formatBalance } from "../../utilities/helperFunctions";
import { useSelector, useDispatch } from "../../utilities/hooks";
import Loader from "../../components/loaders/loading";
import {
  fetchSingleBeneficiaryAction,
  deleteSingleBeneficiaryAction,
} from "../../services/actions/";
import DeleteBeneficiaryModal from "../../components/modals/beneficiaries/DeleteBeneficiary";
import EditBeneficiariesModal from "../../components/modals/beneficiaries/EditBeneficiaries";
import { AccessControl } from "accesscontrol";

export default function BeneficiarySingleOverview({ searchValue }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editBeneficiaryModal, setEditBeneficiaryModal] = useState(false);
  const { singleBeneficiary, loading } = useSelector(
    (state) => state.beneficiariesReducer
  );

  const { user } = useSelector((state) => state.profileReducer);
  const { roles } = useSelector((state) => state.RoleReducer);

  const ac = new AccessControl(roles?.permissions);

  function checkAccess(value) {
    const permission = ac.can(user?.role).createAny(value);
    return permission;
  }

  useEffect(() => {
    dispatch(fetchSingleBeneficiaryAction({ params: { id } }));
  }, [dispatch, id]);

  function handleCloseModal() {
    setShowDeleteModal(false);
    dispatch(fetchSingleBeneficiaryAction({ params: { id } }));
  }

  let editBeneficiary = () => {
    setEditBeneficiaryModal(true);
  };

  const payBeneficiary = () => {
    navigate("/payouts", { state: singleBeneficiary?.beneficiary[0] });
  };

  const goToTransaction = (item) => {
    navigate(`/transaction/details/${item.transactionId}`);
  };

  //table header details
  const columns = [
    {
      name: "",
      selector: (row) => row.status,
      sortable: false,
      grow: 1,
      cell: (row) => {
        return (
          <div className="capitalize-text">
            <LeftCircleFilled style={{ color: "#4CAF50" }} />
            &nbsp; &nbsp;
            {row.type}
          </div>
        );
      },
    },
    {
      name: "",
      selector: (row) => row.status,
      sortable: false,
      grow: 1,
      cell: (row) => {
        if (row.status === "successful") {
          return (
            <div
              style={{
                background: "#DBEFDC",
                borderRadius: "4px",
                border: "1px solid #4CAF50",
                padding: "2px 8px",
                color: "#193A1B",
              }}
              className="capitalize-text"
            >
              {row.status}
            </div>
          );
        } else {
          return (
            <div
              style={{
                background: "#CDCDF9",
                borderRadius: "4px",
                border: "1px solid #0000BB",
                padding: "2px 8px",
                color: "#0000E0",
              }}
            >
              {row.status}
            </div>
          );
        }
      },
    },

    {
      name: "",
      selector: (row) => row.amount,
      sortable: false,
      grow: 1,
      cell: (row) => {
        if (row.amount) {
          return <div>UGX {formatBalance(row.amount)}</div>;
        }
      },
    },
    {
      name: "",
      selector: (row) => row.createdAt,
      sortable: false,
      grow: 1,
      cell: (row) => {
        if (row.createdAt) {
          return <div>{moment(row.createdAt).format("MMM D, YYYY")}</div>;
        }
      },
    },
    {
      name: "",
      sortable: false,
      right: true,
      width: "20px",
      cell: (row) => {
        return (
          <div>
            <Button
              type="link"
              style={{ color: "#48484D" }}
              icon={<DownCircleOutlined />}
              onClick={() => console.log(row)}
            />
          </div>
        );
      },
    },
  ];
  const noRecords = "No transaction records";

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  const handleDeletion = () => {
    setShowDeleteModal(false);
    dispatch(deleteSingleBeneficiaryAction({ params: { id } }));
  };

  // const handleEdit = () => {};

  return (
    <>
      <h1 className="singleWallet-h1">
        <span
          onClick={() => {
            navigate("/beneficiaries");
          }}
        >
          Beneficiary
        </span>
        &nbsp;
        <i className="fa fa-angle-right"></i> Beneficiary Details
      </h1>

      {loading || !singleBeneficiary ? (
        <Loader />
      ) : (
        <>
          <div className="single-beneficial-header">
            <Button className="btn btn-light btn-download ">
              Download transactions
            </Button>
            {checkAccess("payouts").granted
              ? singleBeneficiary?.beneficiary[0].transactions.length > 0 && (
                  <button
                    className="btn btn-primary ms-4 text-bold"
                    value="send"
                    onClick={() => payBeneficiary()}
                  >
                    Pay beneficiary
                  </button>
                )
              : null}
          </div>
          <div>
            <Row className="mt-2">
              <Col flex={45} className="pr-2">
                <div className="single-beneficiary-body single-beneficiary-shadow">
                  {checkAccess("beneficiaries").granted ? (
                    <div className="mt-2 flex align-items-end justify-content-end">
                      <DeleteOutlined
                        onClick={handleDelete}
                        className="icons-ben ms-4 me-4"
                      />
                      <EditOutlined
                        // onClick={handleEdit}
                        className="icons-ben me-4 ms-4"
                        onClick={editBeneficiary}
                      />
                    </div>
                  ) : null}
                  <div className="row">
                    <div className="col-sm-2 p-0">
                      <div className="ant-steps-item-icon xxxLarger mt-5">
                        <span className="ant-steps-icon xxxText float-end">
                          {singleBeneficiary?.beneficiary[0]?.firstName.charAt(
                            0
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-10">
                      <h2 className="text-left remText mt-5">
                        {singleBeneficiary?.beneficiary[0].firstName}
                        &nbsp;&nbsp;
                        {singleBeneficiary?.beneficiary[0].lastName}
                      </h2>
                      <p className="text-left">
                        {singleBeneficiary?.beneficiary[0].email}
                      </p>
                      <p className="text-left">
                        {singleBeneficiary?.beneficiary[0].phoneNumber}
                      </p>
                      <p className="text-left">
                        <ReactCountryFlag
                          countryCode={
                            singleBeneficiary?.beneficiary[0].country
                          }
                          svg
                          cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                          cdnSuffix="svg"
                          title="US"
                          style={{
                            fontSize: "1.5em",
                            lineHeight: "2em",
                            borderRadius: "50px",
                          }}
                        />
                        &nbsp; &nbsp;
                        {
                          ctzc.getCountryByCode(
                            singleBeneficiary?.beneficiary[0].country
                          ).countryName
                        }
                      </p>
                      {/* <p className="text-left">Employee</p>
                  <p className="text-left">Sub: Customer service</p> */}
                      {singleBeneficiary?.beneficiary[0].isBank && (
                        <>
                          <h5 className="text-left remTextH2 mt-5 mb-4">
                            Account information
                          </h5>
                          <b className="bold text-left">Bank name</b>
                          <p className="text-left mb-3">
                            {singleBeneficiary.beneficiary[0].bankName}
                          </p>
                          <b className="bold text-left">Bank account name</b>
                          <p className="text-left mb-3">
                            {singleBeneficiary?.beneficiary[0].bankAccountName}
                          </p>
                          <b className="bold text-left">Bank account number</b>
                          <p className="text-left">
                            {
                              singleBeneficiary?.beneficiary[0]
                                .bankAccountNumber
                            }
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col flex={55}>
                <div className="single-beneficiary-body p-0">
                  <div className="d-flex align-items-end justify-content-start">
                    <h5 className="ms-4 mt-4 mb-4 text-left">
                      Recent transactions
                    </h5>
                  </div>
                  {singleBeneficiary?.beneficiary[0].transactions.length > 0 ? (
                    <div className="row">
                      <div className="col-md-12">
                        <DataTable
                          noHeader={true}
                          noDataComponent={noRecords}
                          columns={columns}
                          data={singleBeneficiary?.beneficiary[0].transactions}
                          pointerOnHover
                          striped
                          customStyles={{
                            headRow: {
                              style: {
                                minHeight: 0,
                              },
                            },
                            rows: {
                              style: {
                                ":hover": {
                                  backgroundColor: "#ECEBFF",
                                },
                              },
                            },
                          }}
                          onRowClicked={(item) => goToTransaction(item)}
                          // pagination
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center align-items-center flex-column"
                      style={{
                        height: "25rem",
                        padding: "20%",
                        textAlign: "center",
                      }}
                    >
                      <img className="mb-5" src={trans} alt="" />
                      <h6 className="mb-4">
                        Recent transactions with &nbsp;
                        {singleBeneficiary?.beneficiary[0]?.firstName} are shown
                        here
                      </h6>
                      <p className="mb-4">
                        You have not performed any tranction with this
                        beneficiary
                      </p>
                      {checkAccess("payouts").granted ? (
                        <button
                          className="btn btn-primary me-4 text-bold"
                          value="send"
                          onClick={() => payBeneficiary()}
                        >
                          Pay {singleBeneficiary?.beneficiary[0]?.firstName}
                        </button>
                      ) : null}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </>
      )}
      {showDeleteModal ? (
        <DeleteBeneficiaryModal
          show={showDeleteModal}
          onClose={handleCloseModal}
          onOk={handleDeletion}
          item={singleBeneficiary?.beneficiary[0]}
        />
      ) : null}
      {editBeneficiaryModal ? (
        <EditBeneficiariesModal
          show={editBeneficiaryModal}
          onClose={() => {
            setEditBeneficiaryModal(false);
            dispatch(fetchSingleBeneficiaryAction({ params: { id } }));
          }}
          item={singleBeneficiary?.beneficiary[0]}

          // itemPosition={itemPosition}
        />
      ) : null}
    </>
  );
}
