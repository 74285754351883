import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import WelcomeModal from "../../components/WelcomeModal";
import StyledModal from "../../components/modals/styled-modal";
import Tabs from "../../components/StartTab";
import Tab from "../../components/StartTab/tab";
import Question from "../../assets/images/Question.svg";
import Document from "../../assets/images/Information.svg";
import Arrow_right from "../../assets/images/Arrow_right.svg";
import {
  fetchWalletsAction,
  fetchBalanceSummaryAction,
  fetchRolesAction,
  fetchIPAddressAction,
  fetchCountryAction,
} from "../../services/actions";

import placeholderFaq from "../../assets/images/faq - b2b.svg";
import featureIcon from "../../assets/images/feature_icon.svg";
import close from "../../assets/images/Close.svg";
import placeholderDocument from "../../assets/images/documents - b2b.svg";
import Loader from "../../components/loaders/loading";
import { useParams, useNavigate } from "react-router-dom";
import OverviewRadioButtonGroup from "./verified/overviewActions/RadioButtons";
import Summary from "./verified/Summary";
import OnboardingEntry from "./onboarding";
import FAQ from "../../components/FAQs";
import Documents from "../../components/Documents";
import { useIntercom } from "react-use-intercom";
import { VERIFICATION_EMAIL, PASSWORD } from "../../utilities/constants";

import TagBanner from "../../components/TagsBanner";
import {
  fetchProfileAction,
  fetchProfileTags,
  selectProfileIsLoading,
  selectProfileTag,
} from "../../services/reducers/authReducers/profileReducer";
import { selectIsTagWhiteListed } from "../../services/reducers/authReducers/twoFactorReducer";
import { openModal } from "../../services/reducers/modal";
import Banner from "../../components/ui/Banner";

export default function DashboardOverview() {
  const { show, boot } = useIntercom();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.profileReducer);

  const isTagWhiteListed = useSelector(selectIsTagWhiteListed);
  const businessTag = useSelector(selectProfileTag);
  const isLoadingProfile = useSelector(selectProfileIsLoading);

  const { ip_address: addresses } = useSelector(
    (state) => state.ipAddressReducer
  );
  const { roles = [] } = useSelector((state) => state?.RoleReducer || {});
  const { wallets, hasCalled, loading } = useSelector(
    (state) => state.walletsReducer
  );

  const { accountSummary, accountCall } = useSelector(
    (state) => state.analyticsReducer
  );

  const [hasAccount, setHasAccount] = useState(false);
  const [onBoardView, setOnBoardView] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [tabModalOpen, setTabModalOpen] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [showNotification, setShowNotification] = useState(
    addresses.length === 0
  );
  const [showTagBanner, setShowTagBanner] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [kybVerified, setKybVerfied] = useState(false);

  boot({
    name: _.get(user, "account.name", ""),
    email: _.get(user, "account.email", ""),
    user_id: _.get(user, "account.id", ""),
    isB2B: "B2B",
  });

  // function googleAnalytics(payload) {
  //   return makeAPICallGoogle({
  //     payload: payload,
  //     method: "POST",
  //   })
  //     .then((res) => {
  //       localStorage.removeItem("GA_LOCAL_STORAGE_KEY");
  //       localStorage.setItem("USER_ACCOUNT_VERIFIED", true);
  //     })
  //     .catch((err) => {
  //       message.error(err.message);
  //     });
  // }

  useEffect(() => {
    if (user?.account?.kybStatus === "verified") {
      setKybVerfied(true);
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (id) {
      onOpenModalTab(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!user) return;

    if (user.account) {
      setHasAccount(true);
    }

    if (user.account?.isVerified) {
      setOnBoardView(false);
      setIsVerified(true);
      dispatch(fetchWalletsAction());
      dispatch(fetchBalanceSummaryAction());
    }

    if (["none", "started", "rejected"].includes(user.account?.kybStatus)) {
      onOpenModal();
    }

    if (user && Object.keys(user).length === 0) {
      dispatch(fetchProfileAction());
    }
  }, [user, dispatch]);

  useEffect(() => {
    setShowLoader(true);
    Promise.all([
      dispatch(fetchCountryAction()),
      dispatch(fetchRolesAction()),
      dispatch(fetchProfileAction()),
      dispatch(fetchIPAddressAction()),
      dispatch(fetchProfileTags()),
    ])
      .then(() => {
        window.sessionStorage.removeItem(VERIFICATION_EMAIL);
        window.sessionStorage.removeItem(PASSWORD);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => setShowLoader(false));
  }, [dispatch]);

  useEffect(() => {
    if (roles) {
      return true;
    } else {
      dispatch(fetchRolesAction());
    }
  }, [roles, dispatch]);

  useEffect(() => {
    if (addresses.length === 0) {
      setShowNotification(addresses.length === 0 && isVerified && kybVerified);
    }
  }, [addresses, isVerified, kybVerified]);

  useEffect(() => {
    setShowTagBanner(!isLoadingProfile && isTagWhiteListed && !businessTag);
  }, [isLoadingProfile, isTagWhiteListed, businessTag]);

  useEffect(() => {
    if (addresses.length === 0) {
      setShowNotification(addresses.length === 0 && isVerified && kybVerified);
    }
  }, [addresses, isVerified, kybVerified]);

  function onCloseModal() {
    setModalOpen(false);
  }

  function onCloseModalTab() {
    setTabModalOpen(false);
    navigate("/overview");
  }

  function onOpenModalTab(index) {
    setModalOpen(false);
    setTabModalOpen(true);
    handleTabClick(index);
  }

  function onOpenModal() {
    setModalOpen(true);
  }

  function handleTabClick(index) {
    setActiveTabIndex(index);
  }

  const skipReg = () => {
    setOnBoardView(false);
  };

  function closeModalContinue() {
    setModalOpen(false);
    setTabModalOpen(false);
    navigate("/overview");
  }

  function closeModalContinueDoc() {
    setModalOpen(false);
    setTabModalOpen(false);
    navigate("/overview");
    setOnBoardView(true);
  }

  function closeModalDoneDoc() {
    setModalOpen(false);
    setTabModalOpen(false);
    navigate("/overview");
  }

  const handleOpenTagModal = () => dispatch(openModal("tagModal"));

  const showOnboardingBanner = user?.account?.kybStatus === "submitted";

  return (
    <div>
      {showLoader ? (
        <React.Fragment>
          <Loader />
        </React.Fragment>
      ) : (
        <div className="content-wrap">
          <div className="row">
            <div className="col-md-12">
              {showOnboardingBanner && (
                <Banner className="mb-4">
                  <div>
                    <p>
                      We've received your documents. Our team is reviewing them.
                    </p>
                    <p>
                      If you need assistance while we review your registration,
                      our support team is here to help!
                    </p>
                  </div>
                </Banner>
              )}
              {showNotification && (
                <div className="for-notifications mb-4">
                  <div className="d-flex justify-content-between flex-md-row flex-column">
                    <div className="d-flex align-items-center">
                      <img
                        src={featureIcon}
                        alt="notification icon"
                        className="me-2"
                      />
                      <p>
                        Not whitelisted your IP address yet? click on the button
                        to whitelist you IP address
                      </p>
                    </div>
                    <div className="d-flex align-items-center w-sm-100 justify-content-between justify-content-md-start mt-2 mt-md-0">
                      <button
                        type="button"
                        className="ev-primary-btn  me-2"
                        onClick={() => navigate("/settings/developers")}
                      >
                        Whitelist IP Address
                      </button>
                      <img
                        src={close}
                        alt="close modal"
                        className="cls-btn"
                        onClick={() => setShowNotification(false)}
                      />
                    </div>
                  </div>
                </div>
              )}

              {showTagBanner && (
                <div className="mb-4">
                  <TagBanner
                    onClose={() => setShowTagBanner(false)}
                    onClick={handleOpenTagModal}
                  />
                </div>
              )}

              <h1 className="welcome-name">
                Hello, {_.get(user, "firstName", "")}
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flex-md-row flex-column align-itemsmd-md-center  justify-content-start justify-content-md-between">
                <p className="welcome-paragraph">
                  {onBoardView
                    ? "Complete your registration to start transacting. "
                    : hasAccount && isVerified && kybVerified
                    ? "Here is an overview of all your business activities"
                    : "Welcome to your business dashboard. You'll see activity here once transactions begin."}
                </p>

                <div>
                  {onBoardView ? (
                    hasAccount && isVerified ? (
                      <button
                        type="submit"
                        onClick={skipReg}
                        className="link-btn"
                      >
                        Skip KYB (Know your business)&nbsp;
                        <img src={Arrow_right} alt="skip registration" />
                      </button>
                    ) : null
                  ) : (
                    <OverviewRadioButtonGroup
                      wallets={wallets}
                      loading={loading}
                      user={user}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3">
            {onBoardView ? (
              <OnboardingEntry
                user={user}
                show={show}
                setOnBoardView={setOnBoardView}
              />
            ) : hasAccount && isVerified ? (
              <>
                <Summary
                  user={user}
                  wallets={wallets}
                  loading={hasCalled}
                  accountLoader={accountCall}
                  accountSummary={accountSummary}
                />
              </>
            ) : null}
          </div>
        </div>
      )}
      {modalOpen ? (
        <WelcomeModal
          title={user?.firstName}
          show={modalOpen}
          onClose={onCloseModal}
        >
          <div className="welcome-modal-container">
            <p className="welcome-modal-p">
              Lets get you started, by answering a few questions you might have
            </p>
            <div className="row">
              <div className="col-md-6">
                <div
                  className="welcome-modal-card"
                  onClick={() => onOpenModalTab(0)}
                >
                  <div className="welcome-modal-image">
                    <img src={placeholderFaq} alt="faq" />
                  </div>
                  <h2>FAQs</h2>
                  <h3>
                    Find all the answers to questions you might have about
                    Eversend B2B
                  </h3>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="welcome-modal-card"
                  onClick={() => onOpenModalTab(1)}
                >
                  <div className="welcome-modal-image">
                    <img src={placeholderDocument} alt="faq" />
                  </div>
                  <h2>Documents to submit</h2>
                  <h3>
                    Learn about documents you will need to Increase transaction
                    limits on your account
                  </h3>
                </div>
              </div>
            </div>
            {/* <p className="welcome-modal-p-footer">
              Have more questions?
              <a href="https://eversend.co">Join the help group</a>
            </p> */}
          </div>
        </WelcomeModal>
      ) : null}
      {tabModalOpen ? (
        <StyledModal show={tabModalOpen} onClose={onCloseModalTab}>
          <div className="tabs-docs">
            <Tabs activeTabIndex={activeTabIndex} onTabClick={handleTabClick}>
              <Tab title="FAQs" icon={Question}>
                <FAQ closeModalContinue={closeModalContinue} />
              </Tab>
              <Tab title="Documents to submit" icon={Document}>
                <Documents
                  user={user}
                  closeModalContinue={closeModalContinueDoc}
                  closeModalDone={closeModalDoneDoc}
                />
              </Tab>
            </Tabs>
          </div>
        </StyledModal>
      ) : null}
    </div>
  );
}
