import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as CloseIcon } from "../../../assets/images/Close.svg";
import styles from "./banner.module.scss";
import Button from "../Button";

const Banner = ({
  icon,
  children,
  ctaText,
  ctaAction,
  handleClose,
  showDismiss,
  className = "",
}) => {
  return (
    <div className={`${styles.banner} ${className}`}>
      <div className={styles.content}>
        <div className={styles.iconWrapper}>
          <div className={styles.icon}>{icon && icon}</div>
        </div>
        {children}
      </div>
      <div className={styles.cta}>
        {ctaText && (
          <Button className={styles.ctaButton} onClick={ctaAction}>
            {ctaText}
          </Button>
        )}
        {showDismiss && (
          <Button
            type="transparent"
            className={styles.closeButton}
            onClick={handleClose}
          >
            <CloseIcon className={styles.closeIcon} />
          </Button>
        )}
      </div>
    </div>
  );
};

Banner.propTypes = {
  icon: PropTypes.element,
  text: PropTypes.node.isRequired,
  children: PropTypes.element,
  ctaText: PropTypes.string,
  showDismiss: PropTypes.bool,
  ctaAction: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.string,
};
export default Banner;
